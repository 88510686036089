import {
    gql
} from '@apollo/client'

const GET_FEED_LIST_QUERY = gql`
query getFeedList($customerId: String, $pageSize: Int, $currentPage: Int){
    getFeedList (
        pageSize: $pageSize,
        filter: {
            customer_id: $customerId
            status: {
                eq: "approved" 
            },
        }
        sort: [
            { created_at: DESC }
        ],
        currentPage: $currentPage
    ) { 
        items {
            feed_id
            status
            type
            content
            is_pinned
            customer_id
            created_at
            privacy
            is_show_thumbnail
             link
            link_title
            link_description
            link_thumbnail
            media_gallery {
                id
                media_type
                video_url
                path
                video_thumbnail
            }
            short_time
            customer {
                email
                firstname
                lastname
                avatar
            }
            total_like
            total_comment
            # link
            # link_title
            # link_description
            # link_thumbnail
            products {
                id
                sku
                name
                price
                qty
                description
                image {
                    name
                    url
                }
            }
            background
            font
        }
        page_info {
            page_size
            current_page
            total_page
        }
    }
}
`

const ADD_REACTION_FEED_QUERY = gql`
    mutation reactionFeed($feedId: Int!, $type: Int!){
        #1: LOVE, 2: LIKE, 3: CONGRATS, 4: WOW, 5: SAD, 6: HAHA, 7: ANGRY
        reactionFeed (
            feed_id: $feedId,
            type: $type
        ) {
            result
        }
    }
`

const DELETE_REACTION_FEED_QUERY = gql`
    mutation unReactionFeed($feedId: Int!, $type: Int!) {
        #1: LOVE, 2: LIKE, 3: CONGRATS, 4: WOW, 5: SAD, 6: HAHA, 7: ANGRY
        unReactionFeed (
            feed_id: $feedId, 
            type: $type
        ) {
            result
        }
    }
`

const UPDATE_REACTION_FEED_QUERY = gql`
    mutation updateReactionFeed($feedId: Int!, $type: Int!, $typeRemove: Int!) {
        #1: LOVE, 2: LIKE, 3: CONGRATS, 4: WOW, 5: SAD, 6: HAHA, 7: ANGRY
        updateReactionFeed (
            feed_id: $feedId,
            type: $type,
            type_removed: $typeRemove
        ) {
            result
        }
    }
`

const UPDATE_PRIVACY_FEED_QUERY = gql`
    mutation updatePrivacy($feedId: Int!, $privacy: String!){
        updatePrivacy (
            feed_id: $feedId
            privacy: $privacy
        ) {
            result
        }
    }
`

const DELETE_FEED_QUERY = gql`
    mutation deleteFeed($id: String) {
        deleteFeed(id: $id) {
            result
        }
    }
`

const extractQueryInfo = (query) => ({
    query: query.loc.source.body,
    name: query.definitions[0].name.value,
});

const FEED_QUERY = {
    GET_FEED_LIST: extractQueryInfo(GET_FEED_LIST_QUERY),
    ADD_REACTION_FEED: extractQueryInfo(ADD_REACTION_FEED_QUERY),
    DELETE_REACTION_FEED: extractQueryInfo(DELETE_REACTION_FEED_QUERY),
    UPDATE_REACTION_FEED: extractQueryInfo(UPDATE_REACTION_FEED_QUERY),
    UPDATE_PRIVACY_FEED: extractQueryInfo(UPDATE_PRIVACY_FEED_QUERY),
    DELETE_FEED: extractQueryInfo(DELETE_FEED_QUERY)
};

export default FEED_QUERY;